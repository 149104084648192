import axios from "axios"

import toastr from "toastr"
import "toastr/build/toastr.min.css"
//apply base url for axios
const API_URL = ""

const axiosApi = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
  },
  credentials: "same-origin",
})

const SystemErrors = {
  AuthenticationFailed: "InvalidUsername or Password",
  InvalidCredentialsError: "Your Username or password combination is invalid",
  NoFilesforUploadError: "Kindy upload files",
  UserAlreadyExists: "User with similar credentials already exists",
  InvalidOTP: "Please enter a valid OTP code",
  MinimumPasswordLengthError: "Password must be atleast 8 characters",
  PasswordCombinationError:
    "Password must contain at least 1 digit and a letter",
  PasswordsDoNotMatchError: "Passwords must be similar",
  UserDoesNotExist: "User does not exist",
  OldPasswordError: "You've entered an old password.",
  UpdateUserError: "An error occurred while updating details",
  UserAlreadyCheckedInError: "User has already checked in!",
  RecordAlreadyExistsError: "Organization with similar details already exists",
  RecordDoesNotExistError: "Record does not exists",
  MultipleAnonymousQuestionsError: "There can only be one anonymous question",
}
const errorMappers = {
  0: "Service is unavailable at this time",
  401: "You are not authorized",
  404: "Resource not found",
  403: "You are not allowed to perform action",
  400: SystemErrors || "An error occurred",
}

export let errorHandler = (error) => {
  const statusCode = error?.response?.status || 500
  const statusType = errorMappers[statusCode]

  const errorType = typeof statusType
  toastr.options.closeButton = true
  if (errorType != "string") {
    // system errors
    const serverError = error.response?.data || {}
    const errorCode = Object.values(serverError)
    let message = SystemErrors[errorCode]
    if (!message) {
      message = errorCode[0] ?? "Failed to perform action. Try again later"
    }
    toastr.error(message)
  } else {
    toastr.error(statusType)
  }
}

axiosApi.interceptors.response.use(
  response => {
    document.body.classList.remove("loading-indicator")
    return response
  },
  error => {
    document.body.classList.remove("loading-indicator")
    return Promise.reject(errorHandler(error));
  }
)

axiosApi.interceptors.request.use(
  async config => {
    document.body.classList.add("loading-indicator")
    const token = localStorage.getItem("authUser")
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`
    }
    return config
  },
  error => {
    return Promise.reject(error)
  }
)

export const get = async (url, payload = {}) => {
  const exemptedSuffix = ["/products", "conferences/featured-event"]
  exemptedSuffix.map((suffix, index) => {
    if (url.endsWith(suffix)) {
    }
  })
  const options = {
    params: payload,
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage.getItem("authUser")}`,
    },
  }
  return await axiosApi.get(url, options).then(response => response)
}

export async function retrieve(url, id) {
  return await axiosApi.get(`${url}/${id}`).then(response => response)
}

export async function post(url, payload = {}) {
  const exemptedSuffix = [
    "/auth/login",
    "/auth/forgot-password",
    "/auth/reset-password",
    "/auth/member-signup",
    "subscriptions/create-member",
    "/conferences/user-checkin",
  ]

  let postRequest = axiosApi
  exemptedSuffix.map(suffix => {
    if (url.endsWith(suffix)) {
      delete postRequest.defaults.headers["Authorization"]
    }
  })

  const isFileUpload = url.includes('documents/upload')
  if (isFileUpload) {
    postRequest.defaults.headers["Content-Type"] = 'multipart/form-data'
  }

  return new Promise((resolve, reject) => {
    postRequest.post(url, payload).then(
      response => {
        resolve(response?.data)
      },
      err => {
        reject(err)
      }
    )
  })
}

export async function put(url, payload = {}) {
  return axiosApi.put(url, payload).then(response => response)
}

export async function del(url, payload = {}) {
  return await axiosApi.delete(url, payload).then(response => response)
}
