export let API_SERVER = process.env.REACT_APP_API_SERVER
// export let API_SERVER = "http://127.0.0.1:8000";

export let apiUrl = API_SERVER + "/api/v1/"
export let loginUrl = apiUrl + "auth/login"
export let forgotPasswordUrl = apiUrl + "auth/forgot-password"
export let resetPasswordUrl = apiUrl + "auth/reset-password"
export let registerMemberUrl = apiUrl + "auth/member-signup"
export let uploadDocumentUrl = apiUrl + "documents/upload"
export let submitAbstractUrl = apiUrl + "conferences/abstracts/submit"
export let listConferencesUrl = apiUrl + "conferences"

export let listProductsUrl = apiUrl + "products"

export let listOrganizationsUrl = apiUrl + "organizations"

export let listIncomingReportUrl = apiUrl + "incoming-reports"
export let exportListIncomingReportUrl = apiUrl + "incoming-reports/export"
export let submitReportUrl = apiUrl + "incoming-reports/staff-act-on-report"
export let listRegionsUrl = apiUrl + "regions"
export let listAllRegionsUrl = apiUrl + "regions/list"
export let listSectorsUrl = apiUrl + "sectors"
export let listLanguagesUrl = apiUrl + "languages"
export let listReportTypesUrl = apiUrl + "reports"
export let listSubscribersUrl = apiUrl + "subscribers"
export let listSubscribersReportUrl = apiUrl + "subscribers/reports"
export let listBlacklistedWordsUrl = apiUrl + "blacklisted-words"
export let listQuestionsUrl = apiUrl + "questions"
export let listReportQuestionsUrl = apiUrl + "questions/report"
export let listUsersUrl = apiUrl + "users"
export let createStaffUrl = apiUrl + "users/create-staff"

export let generateSubscriptionInvoiceUrl =
  apiUrl + "subscriptions/create-member"

export let paypalPaymentConfirmationUrl =
  apiUrl + "payments/paypal-customer-checkout"
export let listInvoicesUrl = apiUrl + "invoices"
export let getInvoiceDetailUrl = apiUrl + "invoices"
export let getConferenceDetailUrl = apiUrl + "conferences"
export let categoriesUrl = apiUrl + "categories"
export let updateBioDataUrl = apiUrl + "users/update-bio"

export let memberDashboardAnalyticsUrl = apiUrl + "customer/analytics/dashboard"
import { del, get, post, put, retrieve } from "./api_helper"

export let eventsUrl = apiUrl + "conferences"
export let eventCheckoutUrl = apiUrl + "conferences/check-out"
export let eventsCheckInUrl = apiUrl + "conferences/user-checkin"

export let ticketsUrl = apiUrl + "conferences/tickets"
export let certificatesUrl = apiUrl + "conferences/certificates"
export let featuredEventUrl = apiUrl + "conferences/featured-event"
export let eventsbulkRegistrationUrl = apiUrl + "conferences/bulk-registration"

export let listDashboardAnalyticsUrl = apiUrl + "analytics/dashboard"
export let analyticsByStatusUrl = apiUrl + "analytics/status"
export let analyticsByRegionUrl = apiUrl + "analytics/region"
export let analyticsByOrganizationUrl = apiUrl + "analytics/organization"
export let analyticsByCategoryUrl = apiUrl + "analytics/category"
export let analyticsByQuestionUrl = apiUrl + "analytics/question"

export let reportAuditTrailUrl = apiUrl + "incoming-reports/audit-history"
export let searchReportUrl = apiUrl + "incoming-reports/search-report"
export let submitManualReportUrl = apiUrl + "incoming-reports/add-report"
export let sendBulkSms = apiUrl + "shared/send-bulk-sms"

export let listDeveloperUsersUrl = apiUrl + "api-keys"

export let apiMapper = {
  LOGIN: {
    url: loginUrl,
    method: "POST",
  },
  FORGOT_PASSWORD: {
    url: forgotPasswordUrl,
    method: "POST",
  },
  RESET_PASSWORD: {
    url: resetPasswordUrl,
    method: "POST",
  },
  DOCUMENT_UPLOAD: {
    url: uploadDocumentUrl,
    method: "POST",
  },
  SUBMIT_ABSTRACT: {
    url: submitAbstractUrl,
    method: "POST",
  },
  FETCH_CATEGORIES: {
    url: categoriesUrl,
    method: "GET",
  },
  FETCH_CONFERENCES: {
    url: listConferencesUrl,
    method: "GET",
  },
  FETCH_INCOMING_REPORTS: {
    url: listIncomingReportUrl,
    method: "GET",
  },
  EXPORT_FETCH_INCOMING_REPORTS: {
    url: exportListIncomingReportUrl,
    method: "GET",
  },
  FETCH_INCOMING_REPORT_DETAIL: {
    url: listIncomingReportUrl,
    method: "RETRIEVE",
  },
  FETCH_INCOMING_REPORT_AUDIT_DETAIL: {
    url: reportAuditTrailUrl,
    method: "GET",
  },
  SEARCH_INCOMING_REPORT: {
    url: searchReportUrl,
    method: "GET",
  },

  UPDATE_INCOMING_REPORT: {
    url: listIncomingReportUrl + "/prod",
    method: "PUT",
  },
  FETCH_INVOICES: {
    url: listInvoicesUrl,
    method: "GET",
  },
  FETCH_INVOICE_DETAIL: {
    url: getInvoiceDetailUrl,
    method: "RETRIEVE",
  },
  MEMBER_REGISTRATION: {
    url: registerMemberUrl,
    method: "POST",
  },
  GENERATE_SUBSCRIPTION_INVOICE: {
    url: generateSubscriptionInvoiceUrl,
    method: "POST",
  },
  FETCH_SUBSCRIPTIONS: {
    url: listProductsUrl,
    method: "GET",
  },
  POST_PAYPAL_CONFIRMATION: {
    url: paypalPaymentConfirmationUrl,
    method: "POST",
  },
  FETCH_DASHBOARD_ANALYTICS: {
    url: memberDashboardAnalyticsUrl,
    method: "GET",
  },

  FETCH_REPORTS_BY_STATUS: {
    url: analyticsByStatusUrl,
    method: "GET",
  },

  FETCH_REPORTS_BY_REGION: {
    url: analyticsByRegionUrl,
    method: "GET",
  },

  FETCH_REPORTS_BY_ORGANIZATION: {
    url: analyticsByOrganizationUrl,
    method: "GET",
  },

  FETCH_REPORTS_BY_CATEGORY: {
    url: analyticsByCategoryUrl,
    method: "GET",
  },

  FETCH_REPORTS_BY_QUESTION: {
    url: analyticsByQuestionUrl,
    method: "GET",
  },

  UPDATE_MY_PROFILE: {
    url: updateBioDataUrl,
    method: "PUT",
  },

  FETCH_CONFERENCE_DETAIL: {
    url: getConferenceDetailUrl,
    method: "RETRIEVE",
  },
  EVENT_CHECKOUT: {
    url: eventCheckoutUrl,
    method: "POST",
  },
  FETCH_TICKETS: {
    url: ticketsUrl,
    method: "GET",
  },
  FETCH_ATTENDANCE_CERTIFICATES: {
    url: certificatesUrl,
    method: "GET",
  },
  FETCH_TICKET_DETAIL: {
    url: ticketsUrl,
    method: "RETRIEVE",
  },
  FETCH_CERTIFICATE_DETAIL: {
    url: certificatesUrl,
    method: "RETRIEVE",
  },
  EVENT_CHECK_IN: {
    url: eventsCheckInUrl,
    method: "POST",
  },
  BULK_EVENT_REGISTRATION: {
    url: eventsbulkRegistrationUrl,
    method: "POST",
  },
  FETCH_CURRENT_EVENT: {
    url: featuredEventUrl,
    method: "GET",
  },

  FETCH_API_DEVELOPERS: {
    url: listDeveloperUsersUrl,
    method: "GET",
  },
  CREATE_API_KEY: {
    url: listDeveloperUsersUrl,
    method: "POST",
  },
  UPDATE_API_KEY: {
    url: listDeveloperUsersUrl,
    method: "PUT",
  },
  FETCH_API_DEVELOPER_DETAIL: {
    url: listDeveloperUsersUrl,
    method: "RETRIEVE",
  },

  FETCH_REGIONS: {
    url: listRegionsUrl,
    method: "GET",
  },
  FETCH_ALL_REGIONS: {
    url: listAllRegionsUrl,
    method: "GET",
  },

  FETCH_REGION_DETAIL: {
    url: listRegionsUrl,
    method: "RETRIEVE",
  },
  UPDATE_REGION: {
    url: listRegionsUrl,
    method: "PUT",
  },
  CREATE_REGION: {
    url: listRegionsUrl,
    method: "POST",
  },
  DELETE_REGION: {
    url: listRegionsUrl,
    method: "DELETE",
  },

  FETCH_SECTORS: {
    url: listSectorsUrl,
    method: "GET",
  },

  FETCH_SECTOR_DETAIL: {
    url: listSectorsUrl,
    method: "RETRIEVE",
  },
  UPDATE_SECTOR: {
    url: listSectorsUrl,
    method: "PUT",
  },
  CREATE_SECTOR: {
    url: listSectorsUrl,
    method: "POST",
  },
  DELETE_SECTOR: {
    url: listSectorsUrl,
    method: "DELETE",
  },

  FETCH_QUESTIONS: {
    url: listQuestionsUrl,
    method: "GET",
  },
  FETCH_REPORT_QUESTIONS: {
    url: listReportQuestionsUrl,
    method: "GET",
  },
  SUBMIT_MANUAL_REPORT: {
    url: submitManualReportUrl,
    method: "POST",
  },

  FETCH_QUESTION_DETAIL: {
    url: listQuestionsUrl,
    method: "RETRIEVE",
  },
  UPDATE_QUESTION: {
    url: listQuestionsUrl + "/prod",
    method: "PUT",
  },
  CREATE_QUESTION: {
    url: listQuestionsUrl,
    method: "POST",
  },
  DELETE_QUESTION: {
    url: listQuestionsUrl,
    method: "DELETE",
  },

  FETCH_BLACK_LISTED_WORDS: {
    url: listBlacklistedWordsUrl,
    method: "GET",
  },

  FETCH_BLACK_LISTED_WORD_DETAIL: {
    url: listBlacklistedWordsUrl,
    method: "RETRIEVE",
  },
  UPDATE_BLACK_LISTED_WORD: {
    url: listBlacklistedWordsUrl + "/prod",
    method: "PUT",
  },
  CREATE_BLACK_LISTED_WORD: {
    url: listBlacklistedWordsUrl,
    method: "POST",
  },
  DELETE_BLACK_LISTED_WORD: {
    url: listBlacklistedWordsUrl,
    method: "DELETE",
  },

  FETCH_LANGUAGES: {
    url: listLanguagesUrl,
    method: "GET",
  },

  FETCH_LANGUAGE_DETAIL: {
    url: listLanguagesUrl,
    method: "RETRIEVE",
  },
  UPDATE_LANGUAGE: {
    url: listLanguagesUrl + "/prod",
    method: "PUT",
  },
  CREATE_LANGUAGE: {
    url: listLanguagesUrl,
    method: "POST",
  },
  DELETE_LANGUAGE: {
    url: listLanguagesUrl,
    method: "DELETE",
  },

  FETCH_SUBSCRIBERS: {
    url: listSubscribersUrl,
    method: "GET",
  },
  FETCH_SUBSCRIBER_DETAIL: {
    url: listSubscribersUrl,
    method: "RETRIEVE",
  },
  FETCH_SUBSCRIBER_REPORTS: {
    url: listSubscribersReportUrl,
    method: "GET",
  },
  UPDATE_SUBSCRIBER: {
    url: listSubscribersUrl,
    method: "PUT",
  },

  FETCH_REPORT_TYPES: {
    url: listReportTypesUrl,
    method: "GET",
  },

  FETCH_REPORT_TYPE_DETAIL: {
    url: listReportTypesUrl,
    method: "RETRIEVE",
  },
  UPDATE_REPORT_TYPE: {
    url: listReportTypesUrl + "/prod",
    method: "PUT",
  },
  CREATE_REPORT_TYPE: {
    url: listReportTypesUrl,
    method: "POST",
  },
  DELETE_REPORT_TYPES: {
    url: listReportTypesUrl,
    method: "DELETE",
  },

  FETCH_ORGANIZATIONS: {
    url: listOrganizationsUrl,
    method: "GET",
  },

  FETCH_ORGANIZATION_DETAIL: {
    url: listOrganizationsUrl,
    method: "RETRIEVE",
  },
  UPDATE_ORGANIZATION: {
    url: listOrganizationsUrl,
    method: "PUT",
  },
  CREATE_ORGANIZATION: {
    url: listOrganizationsUrl,
    method: "POST",
  },
  DELETE_ORGANIZATIONS: {
    url: listOrganizationsUrl,
    method: "DELETE",
  },

  FETCH_USERS: {
    url: listUsersUrl,
    method: "GET",
  },

  FETCH_USER_DETAIL: {
    url: listUsersUrl,
    method: "RETRIEVE",
  },
  UPDATE_USER: {
    url: listUsersUrl,
    method: "PUT",
  },
  CREATE_USER: {
    url: listUsersUrl,
    method: "POST",
  },
  RESET_USER_PASSWORD: {
    url: resetPasswordUrl,
    method: "POST",
  },
  DELETE_USER: {
    url: listUsersUrl,
    method: "DELETE",
  },
  CREATE_STAFF: {
    url: createStaffUrl,
    method: "POST",
  },

  FETCH_DASHBOARD_ANALYTICS: {
    url: listDashboardAnalyticsUrl,
    method: "GET",
  },

  ACT_ON_REPORT: {
    url: submitReportUrl,
    method: "POST",
  },

  // send bulk sms
  SEND_BULK_SMS: {
    url: sendBulkSms,
    method: "POST",
  },
}

export let makeApiCall = (method, url, payload) => {
  const methodMapper = {
    POST: post,
    GET: get,
    PUT: put,
    DELETE: del,
    RETRIEVE: retrieve,
  }
  const apiClass = methodMapper[method]
  return apiClass(url, payload)
}
