import React from "react"
import { Link } from "react-router-dom"
import withRouter from "components/Common/withRouter"
import "react-datepicker/dist/react-datepicker.css"

import {
  OrderId,
  DateFormatter,
  ApplicationStatus,
} from "../../templates/tableCol"
import TableContainer from "../../components/Common/TableContainer"

const ReportList = props => {
  const columns = [
    {
      Header: "Reference Number",
      accessor: "reference_number",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return <OrderId {...cellProps} />
      },
    },
    {
      Header: "Report",
      accessor: "input_text",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return <OrderId {...cellProps} />
      },
    },

    {
      Header: "Date Created",
      accessor: "created_at",
      filterable: false,
      disableFilters: true,
      Cell: cellProps => {
        return <DateFormatter {...cellProps} />
      },
    },

    {
      Header: "Status",
      accessor: "status",
      disableFilters: true,
      filterable: false,
      Cell: cellProps => {
        return <ApplicationStatus {...cellProps} />
      },
    },
    {
      Header: "Action",
      accessor: "action",
      disableFilters: true,
      Cell: ({ row }) => {
        return (
          <div>
            <Link
              to={{
                pathname: `/report/${row.original.id}`,
              }}
              className="btn btn-primary btn-sm w-xs"
            >
              View
            </Link>
          </div>
        )
      },
    },
  ]

  return (
    <React.Fragment>
      <TableContainer
        columns={columns}
        data={props.records}
        isGlobalFilter={false}
        paginationParams={props.paginationParams}
        isAddOptions={false}
        customPageSize={10}
        NavigateToPage={props.NavigateToPage}
      />
    </React.Fragment>
  )
}

export default withRouter(ReportList)
