import React, { useState, useEffect, useMemo, useRef } from "react"
import PropTypes from "prop-types"
import withRouter from "components/Common/withRouter"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Table,
  CardTitle,
  Badge,
  FormGroup,
  Label,
  Input,
  Form,
  FormFeedback,
  Button,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  UncontrolledTooltip,
} from "reactstrap"
import Breadcrumbs from "components/Common/Breadcrumb"
import CustomModal from "../../components/Common/CustomModal"
import { invokeApiCall } from "../../api"
import { isEmpty, map } from "lodash"
import { useFormik } from "formik"
import * as Yup from "yup"
import toastr from "toastr"
import classnames from "classnames"
import TableContainer from "../../components/Common/TableContainer"
import { OrderId, DateFormatter } from "../../templates/tableCol"
//redux
import { useSelector, useDispatch } from "react-redux"
import { formatDate, formatWorkflowNode } from "../../templates/tableCol"
import { useNavigate } from "react-router-dom"
import { Can } from "permissions/Can"
import { checkPermissions } from "permissions/ability"
import { getAuthenticatedUser } from "api/user"
import { useReactToPrint } from 'react-to-print';
import './ReportDetail.css'

const ApplicationStatus = ({ status }) => {
  switch (status) {
    case "ONGOING":
      return <Badge className="bg-warning"> {status} </Badge>

    case "COMPLETED":
      return <Badge className="bg-success"> APPROVED </Badge>

    case "REJECTED":
      return <Badge className="bg-danger"> {status} </Badge>

    default:
      return <Badge className="bg-warning"> {status} </Badge>
  }
}
const ReportDetail = props => {
  //meta title
  document.title = "Report Detail"
  const [record, setRecord] = useState({})
  const [incomingResponses, setincomingResponses] = useState([])
  const [selectedActionNode, setselectedActionNode] = useState("")
  const [selectedActionName, setselectedActionName] = useState("")
  const [activeTab, setactiveTab] = useState("1")
  const toggle = tab => {
    if (activeTab !== tab) {
      if (tab == "2") {
        const queryParams = {
          id: record.id,
        }
        fetchReportAuditTrail(queryParams)
      }
      setactiveTab(tab)
    }
  }
  const [Organizations, setOrganizations] = useState([])
  const [Regions, setRegions] = useState([])
  const [AuditLogs, setAuditLogs] = useState([])
  const [ReportCategories, setReportCategories] = useState([])
  const dispatch = useDispatch()
  const [showModal, setshowModal] = useState(false)
  const navigate = useNavigate()

  const [editResponse, setEditResponse] = useState("")
  const [editedReponse, setEditedReponse] = useState({})
  const [showAuditDetailModal, setShowAuditDetailModal] = useState(false)
  const [auditDetail, setAuditDetail] = useState(null)
  const user = getAuthenticatedUser()

	const contentRef = useRef(null);

  const onEditReportResponse = e => {
    setEditedReponse({
      ...editedReponse,
      [e.target.name]: e.target.value,
    })
  }

	const handlePrint = useReactToPrint({
    documentTitle: `Report-${record.reference_number}`,
    content: () => contentRef.current,
    removeAfterPrint: true,
  });
  

  const Auditcolumns = useMemo(
    () => [
      {
        Header: "User",
        accessor: "actor_details.user_reference.full_name",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },

      {
        Header: "State",
        accessor: "workflow_node.formatted_node_name",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Comments",
        accessor: "actor_details.comment",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <OrderId {...cellProps} />
        },
      },
      {
        Header: "Date",
        accessor: "time_created",
        filterable: false,
        disableFilters: true,
        Cell: cellProps => {
          return <DateFormatter {...cellProps} />
        },
      },
      {
        Header: "Action",
        accessor: "action",
        disableFilters: true,
        Cell: ({ row }) => {
          if (row?.original?.actor_details?.existing_payload) {
            return (
              <div className="d-flex gap-3">
                <Button
                  type="button"
                  color="info"
                  className="btn-sm btn-rounded"
                  onClick={e => {
                    e.preventDefault()
                    setShowAuditDetailModal(!showAuditDetailModal)
                    setAuditDetail(
                      row?.original?.actor_details?.existing_payload
                    )
                  }}
                >
                  <i className="mdi mdi-eye font-size-18" id="edittooltip" />
                  <UncontrolledTooltip placement="top" target="edittooltip">
                    View
                  </UncontrolledTooltip>
                </Button>
              </div>
            )
          }
          return
        },
      },
    ],
    []
  )

  const params = props.router.params
  const reportValidation = useFormik({
    enableReinitialize: true,
    validateOnMount: true,

    initialValues: {
      sector_id: record?.sector?.id,
      report_id: record?.report?.id,
      input_text: record?.input_text,
    },
    validationSchema: Yup.object({
      input_text: Yup.string().required("Please add report"),
      sector_id: Yup.string().required("Please add sector"),
      report_id: Yup.string().required("Please add category"),
    }),
  })

  const selectedNodeAction = actionDetails => {
    const actionNode = actionDetails?.actionNode
    const actionName = actionDetails?.actionName

    setselectedActionNode(actionNode)
    setselectedActionName(actionName)
    reportValidation.handleSubmit()
    if (reportValidation.isValid) {
      setshowModal(true)
    }
  }

  const additionalValidation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      forward_to_organization:
        selectedActionNode === "REPORT_FORWARDED_TO_ORGANIZATION",
      organization: "",
      comment: "",
      send_email: record?.sent_email <= 0,
    },
    validationSchema: Yup.object({
      forward_to_organization: Yup.boolean(),
      comment: Yup.string().required("Please enter your comment"),
      organization: Yup.string().when("forward_to_organization", {
        is: true,
        then: Yup.string().required("Please select organization"),
      }),
      send_email: Yup.boolean().optional()
    }),

    onSubmit: payload => {
      const orgPayload = payload

      const reportValues = reportValidation.values
      const formPayload = {
        action: selectedActionNode,
        ...orgPayload,
        ...reportValues,
        report_reponses: editedReponse,
      }
      staffActonReport(formPayload)
    },
  })

  const staffActonReport = payload => {
    payload["id"] = record.id
    invokeApiCall("ACT_ON_REPORT", payload).then(res => {
      if (res) {
        toastr.success("Success")
        setshowModal(false)
        setselectedActionNode("")
        if (selectedActionNode !== "REPORT_UPDATED") {
          navigate("/new-reports")
        }
      }
    })
  }

  const fetchReportAuditTrail = params => {
    invokeApiCall("FETCH_INCOMING_REPORT_AUDIT_DETAIL", params).then(res => {
      const results = res.data
      setAuditLogs(results.results)
    })
  }

  const fetchSectors = params => {
    invokeApiCall("FETCH_SECTORS", params).then(res => {
      const results = res.data
      setRegions(results.results)
    })
  }

  const fetchReportCategories = params => {
    invokeApiCall("FETCH_REPORT_TYPES", params).then(res => {
      const results = res.data
      setReportCategories(results.results)
    })
  }
  useEffect(() => {
    const filterParams = {
      page_size: 1000,
    }
    fetchSectors(filterParams)
    fetchReportCategories(filterParams)
  }, [])

  const handleDeleteEvent = () => {
    // setshowModal(false);
    toggle()
  }

  useEffect(() => {
    if (params && params.id) {
      fetchReportDetail(params.id)
    }
  }, [])
  useEffect(() => {
    fetchOrganizations()
  }, [])

  const fetchReportDetail = params => {
    invokeApiCall("FETCH_INCOMING_REPORT_DETAIL", params).then(res => {
      const results = res.data
      setRecord(results)
      setincomingResponses(results["incoming_responses"])
    })
  }
  const fetchOrganizations = params => {
    invokeApiCall("FETCH_ORGANIZATIONS", params).then(res => {
      const results = res.data
      setOrganizations(results.results)
    })
  }

  const recordAtOrg =
    [
      "REPORT_FORWARDED_TO_ORGANIZATION",
      "REPORT_RETURNED_TO_ORGANIZATION_BY_REPORTER",
      // "REPORT_FORWARDED_TO_REPORTER",
    ].includes(record?.workflow_node?.current_node) &&
    (user?.userRoles?.includes("ORGANIZATION") ||
      user?.userRoles?.includes("ADMIN"))

  const recortAtConvener =
    [
      "REPORT_FORWARDED_TO_CONVENER",
      "REPORT_UPDATED",
      "REPORT_RETURNED_TO_CONVENER",
    ].includes(record?.workflow_node?.current_node) &&
    (user?.userRoles?.includes("CONVENER") ||
      user?.userRoles?.includes("ADMIN"))

  const canActOnReport =
    (recordAtOrg || recortAtConvener) && checkPermissions(["can_update_report"])

  const recordIsEditable =
    recortAtConvener &&
    record?.status === "ONGOING" &&
    checkPermissions(["can_update_report"])

  return (
    <React.Fragment>
      <CustomModal
        show={showModal}
        onDeleteClick={handleDeleteEvent}
        onCloseClick={() => setshowModal(false)}
        size="md"
        addSubmitButton
        onActionSubmit={() => {
          additionalValidation.handleSubmit()
        }}
        title={selectedActionName}
      >
        <Form
          onSubmit={e => {
            e.preventDefault()
            additionalValidation.handleSubmit()
            return false
          }}
        >
          {selectedActionNode === "REPORT_FORWARDED_TO_ORGANIZATION" && (
            <FormGroup className="mb-3">
              <Label className="form-label">Select organization</Label>
              <Input
                type="select"
                className="form-control"
                name="organization"
                onChange={additionalValidation.handleChange}
                onBlur={additionalValidation.handleBlur}
                value={additionalValidation.values.organization}
                invalid={
                  additionalValidation.touched.organization &&
                    additionalValidation.errors.organization
                    ? true
                    : false
                }
              >
                <option value="">-- ---</option>
                {Organizations.map(record => {
                  return (
                    <option key={record.id} value={record.id}>
                      {record.name}
                    </option>
                  )
                })}
                {additionalValidation.touched.organization &&
                  additionalValidation.errors.organization ? (
                  <FormFeedback type="invalid">
                    {additionalValidation.errors.organization}
                  </FormFeedback>
                ) : null}
              </Input>
            </FormGroup>
          )}
          <FormGroup className="mb-3">
            <Label className="form-label">Comment</Label>
            <Input
              type="textarea"
              className="form-control"
              name="comment"
              onChange={additionalValidation.handleChange}
              onBlur={additionalValidation.handleBlur}
              value={additionalValidation.values.comment}
              invalid={
                additionalValidation.touched.comment &&
                  additionalValidation.errors.comment
                  ? true
                  : false
              }
            >
              {additionalValidation.touched.comment &&
                additionalValidation.errors.comment ? (
                <FormFeedback type="invalid">
                  {additionalValidation.errors.comment}
                </FormFeedback>
              ) : null}
            </Input>
          </FormGroup>
          {selectedActionNode === "REPORT_FORWARDED_TO_ORGANIZATION" &&
            <FormGroup className="d-flex gap-2 align-items-center">
              <Input
                type="checkbox"
                className="form-control"
                id="send_email"
                name="send_email"
                defaultChecked={additionalValidation.values.send_email}
                onClick={(e) => {
                  additionalValidation.setFieldValue(
                    "send_email",
                    !!!additionalValidation.values.send_email
                  )
                }}
                onBlur={additionalValidation.handleBlur}
              />
              <Label className="form-check-label p-1" for="send_email">Notify Organization Via Email</Label>
            </FormGroup>
          }
          
        </Form>
      </CustomModal>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs
            title="Incoming report"
            breadcrumbItem="Report Details"
          />
          <Nav tabs>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "1",
                })}
                onClick={() => {
                  toggle("1")
                }}
              >
                Report Details
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: activeTab === "2",
                })}
                onClick={() => {
                  toggle("2")
                }}
              >
                Report Activities
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab} className="body">
            <TabPane tabId="1">
              {!isEmpty(record) && (
                <div className="p-3" ref={contentRef}>
                  <Card>
                    <Row>

                      <div className="text-center p-2 text-decoration-underline">
                        <h4>Report {record.reference_number}</h4>
                      </div>
                      <Col lg="6">
                        <Card>
                          <CardBody>
                            <div className="d-flex">
                              {/* <img src={img1} alt="" className="avatar-sm me-4" /> */}

                              <div className="text-left">
                                <h4>Status:</h4>
                                <h5>
                                  <ApplicationStatus status={record.status} />
                                </h5>
                              </div>
                            </div>

                            <h5 className="font-size-15 mt-4">
                              <u>Report Details :</u>
                            </h5>

                            <div className="table-responsive">
                              <Form>
                                <Table className="table-wrap mb-0">
                                  <tbody>
                                    <tr>
                                      <th scope="row">Tracking Number:</th>
                                      <td>{record.reference_number}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">From :</th>
                                      <td>{record?.report_from}</td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Platform:</th>
                                      <td>
                                        {record?.platform}{" "}
                                        {record?.app_name &&
                                          `(${record?.app_name})`}
                                      </td>
                                    </tr>
                                    <tr>
                                      <th scope="row">Organization:</th>
                                      <td>{record?.organization?.name}</td>
                                    </tr>

                                    <tr>
                                      <th scope="row">Report:</th>
                                      <td>
                                        {!recordIsEditable ? (
                                          <>{record?.input_text}</>
                                        ) : (
                                          <>
                                            <Input
                                              rows="10"
                                              cols="50"
                                              bsSize="lg"
                                              type={"textarea"}
                                              className="form-control"
                                              name="input_text"
                                              onChange={
                                                reportValidation.handleChange
                                              }
                                              onBlur={
                                                reportValidation.handleBlur
                                              }
                                              value={
                                                reportValidation.values
                                                  .input_text || ""
                                              }
                                              invalid={
                                                reportValidation.touched
                                                  .input_text &&
                                                  reportValidation.errors
                                                    .input_text
                                                  ? true
                                                  : false
                                              }
                                            ></Input>
                                            {reportValidation.touched
                                              .input_text &&
                                              reportValidation.errors
                                                .input_text && (
                                                <FormFeedback type="invalid">
                                                  {
                                                    reportValidation.errors
                                                      .input_text
                                                  }
                                                </FormFeedback>
                                              )}
                                          </>
                                        )}
                                      </td>
                                    </tr>

                                    <tr className="hide-item-in-print">
                                      <th scope="row">Documents:</th>
                                      <td className="d-flex flex-column">
                                        {record?.documents.map(document => {
                                          return (
                                            <span key={document.id}>
                                              {"-> "}
                                              <a
                                                href={document?.document?.file}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                              >
                                                {
                                                  document?.document
                                                    ?.original_file_name
                                                }
                                              </a>
                                            </span>
                                          )
                                        })}
                                      </td>
                                    </tr>

                                    <tr>
                                      <th scope="row">Category:</th>
                                      <td>
                                        <Input
                                          disabled={!recordIsEditable}
                                          type={"select"}
                                          className="form-control"
                                          id="basicpill-email-input4"
                                          placeholder="Select category"
                                          name="report_id"
                                          onChange={
                                            reportValidation.handleChange
                                          }
                                          onBlur={reportValidation.handleBlur}
                                          value={
                                            reportValidation.values.report_id ||
                                            ""
                                          }
                                          invalid={
                                            reportValidation.touched
                                              .report_id &&
                                              reportValidation.errors.report_id
                                              ? true
                                              : false
                                          }
                                        >
                                          <option
                                            value={""}
                                            disabled
                                            hidden
                                          ></option>
                                          {ReportCategories?.length &&
                                            ReportCategories?.map((item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.name}
                                              </option>
                                            ))}
                                        </Input>
                                        {reportValidation.touched.report_id &&
                                          reportValidation.errors.report_id ? (
                                          <FormFeedback type="invalid">
                                            {reportValidation.errors.report_id}
                                          </FormFeedback>
                                        ) : null}
                                      </td>
                                    </tr>

                                    <tr>
                                      <th scope="row">Sector:</th>
                                      <td>
                                        <Input
                                          disabled={!recordIsEditable}
                                          type={"select"}
                                          className="form-control"
                                          id="basicpill-email-input4"
                                          placeholder="Select region"
                                          name="sector_id"
                                          onChange={
                                            reportValidation.handleChange
                                          }
                                          onBlur={reportValidation.handleBlur}
                                          value={
                                            reportValidation.values.sector_id ||
                                            ""
                                          }
                                          invalid={
                                            reportValidation.touched
                                              .sector_id &&
                                              reportValidation.errors.sector_id
                                              ? true
                                              : false
                                          }
                                        >
                                          <option
                                            value={""}
                                            disabled
                                            hidden
                                          ></option>
                                          {Regions?.length &&
                                            Regions?.map((item, i) => (
                                              <option key={i} value={item.id}>
                                                {item.name}
                                              </option>
                                            ))}
                                        </Input>
                                        {reportValidation.touched.sector_id &&
                                          reportValidation.errors.sector_id ? (
                                          <FormFeedback type="invalid">
                                            {reportValidation.errors.sector_id}
                                          </FormFeedback>
                                        ) : null}
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                              </Form>
                            </div>

                            <Row className="task-dates">
                              <Col lg="6" xs="6">
                                <div className="mt-4">
                                  <h5 className="font-size-14">
                                    <i className="bx bx-calendar-check me-1 text-primary" />{" "}
                                    Date created
                                  </h5>
                                  <p className="text-muted mb-0">
                                    {formatDate(record?.created_at)}
                                  </p>
                                </div>
                              </Col>
                              <Col lg="6" xs="6">
                                <div className="mt-4">
                                  <h5 className="font-size-14">
                                    <i className="bx bx-command me-1 text-primary" />{" "}
                                    Report state
                                  </h5>
                                  <p className="text-bold mb-2 text-success">
                                    {formatWorkflowNode(
                                      record?.workflow_node?.current_node
                                    )}
                                  </p>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg="6">
                        <Card className="shadow-sm">
                          <CardBody>
                            <CardTitle className="mb-4">Responses</CardTitle>
                            <div className="table-responsive">
                              <Table className="table-wrap align-middle table-hover mb-0">
                                <thead>
                                  <tr>
                                    <th scope="col" style={{ width: "100px" }}>
                                      #
                                    </th>
                                    <th scope="col">Question</th>
                                    <th scope="col">Answer</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {map(incomingResponses, (record, index) => (
                                    <tr key={index}>
                                      <td> {index + 1}</td>
                                      <td> {record.question}</td>
                                      <td>
                                        {editResponse === record.id ? (
                                          <>
                                            {record?.question_option?.length <
                                              1 ? (
                                              <Input
                                                name={record.id}
                                                onChange={onEditReportResponse}
                                                value={
                                                  editedReponse[record.id] ??
                                                  record.response
                                                }
                                                type="text"
                                              />
                                            ) : (
                                              <Input
                                                name={record.id}
                                                onChange={onEditReportResponse}
                                                value={
                                                  editedReponse[record.id] ??
                                                  record.response
                                                }
                                                type="select"
                                              >
                                                {record?.question_option?.map(
                                                  (item, index) => (
                                                    <option
                                                      key={index}
                                                      value={item.option}
                                                    >
                                                      {item.option}
                                                    </option>
                                                  )
                                                )}
                                              </Input>
                                            )}
                                          </>
                                        ) : (
                                          <>
                                            {editedReponse[record.id] ||
                                              record.response}
                                          </>
                                        )}
                                      </td>
                                      <Can I="user" a="can_update_report">
                                        {recordIsEditable && (
                                          <td>
                                            <Button
                                              size="sm"
                                              type="button"
                                              color="primary"
                                              className="w-sm"
                                              onClick={() => {
                                                setEditResponse(
                                                  editResponse === record.id
                                                    ? ""
                                                    : record.id
                                                )
                                              }}
                                            >
                                              Edit
                                            </Button>
                                          </td>
                                        )}
                                      </Can>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                          </CardBody>
                        </Card>
                        {record?.enquiries?.length > 0 && (
                          <Card className="shadow-sm">
                            <CardBody>
                              <CardTitle className="mb-4">Enquiries</CardTitle>
                              <div className="table-responsive adjust-enquiry">
                                <Table
                                  className="table-wrap table-bordered rounded"
                                  striped
                                >
                                  <tbody>
                                    {record?.enquiries?.map((item, index) => (
                                      <tr
                                        key={index}
                                        className={`${item?.enquiry_type === "ENQUIRY"
                                            ? "text-start"
                                            : "text-end"
                                          }`}
                                      >
                                        <td>
                                          <span
                                            style={{
                                              width: "10px",
                                              color:
                                                item?.enquiry_type === "ENQUIRY"
                                                  ? "purple"
                                                  : "black",
                                            }}
                                          >
                                            {item.input_text}
                                          </span>
                                          <br />
                                          <small
                                            style={{
                                              fontSize: "9px",
                                            }}
                                          >
                                            {item?.enquiry_type === "ENQUIRY"
                                              ? `Organization - ${formatDate(
                                                item.created_at,
                                                "DD MMM Y HH:mm"
                                              )}`
                                              : `${formatDate(
                                                item.created_at,
                                                "DD MMM Y HH:mm"
                                              )} - Reporter`}
                                          </small>
                                        </td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                    </Row>
                    <div className="hide-item-in-print">
                      {canActOnReport &&
                        record?.allowable_next_actions?.length > 0 && (
                          <Card>
                            <CardBody>
                              <CardTitle className="mb-4">Actions</CardTitle>
                              <Row>
                                <Col lg={6}>
                                  <Card>
                                    <CardBody>
                                      <div className="d-flex hideItemInPrint flex-wrap gap-2">
                                        {record?.allowable_next_actions?.map(
                                          record => {
                                            return (
                                              <Button
                                                key={record.action_name}
                                                color="primary"
                                                className="btn btn-primary waves-effect waves-light"
                                                onClick={() => {
                                                  const actionDetails = {
                                                    actionNode:
                                                      record.action_code,
                                                    actionName:
                                                      record.action_name,
                                                  }
                                                  selectedNodeAction(
                                                    actionDetails
                                                  )
                                                }}
                                              >
                                                {record.action_name}
                                              </Button>
                                            )
                                          }
                                        )}
                                      </div>
                                    </CardBody>
                                  </Card>
                                </Col>
                              </Row>
                          </CardBody>
                        </Card>
                      )}
                    
                      <Row>
                        <Col className="d-flex" style={{ justifyContent:'flex-end', marginRight:'5px', marginBottom:'5px'}}>
                          <Button
                            color="primary"
                            className="btn btn-warning waves-effect waves-light"
                            onClick={handlePrint}>
                            Export Pdf
                          </Button>
                        </Col>
                      </Row>
                    </div>
                  </Card>
                </div>
              )}
            </TabPane>
            <TabPane tabId="2">
              <TableContainer
                columns={Auditcolumns}
                data={AuditLogs}
                isGlobalFilter={false}
                isAddOptions={false}
                customPageSize={10}
              />
              {showAuditDetailModal && (
                <DisplayAuditDetails
                  showModal={showAuditDetailModal}
                  setshowModal={() => setShowAuditDetailModal(false)}
                  auditDetail={auditDetail}
                />
              )}
            </TabPane>
          </TabContent>
        </Container>
      </div>
    </React.Fragment>
  )
}

ReportDetail.propTypes = {
  match: PropTypes.object,
}

export default withRouter(ReportDetail)

const DisplayAuditDetails = ({ showModal, setshowModal, auditDetail }) => {
  return (
    <CustomModal
      show={showModal}
      onCloseClick={() => {
        setshowModal(false)
      }}
      size="lg"
      title="Report History"
    >
      <div className="table-responsive react-table">
        <Table
          bordered
          className="table-wrap table-hover mb-0 text-start"
          responsive
          striped
        >
          <tbody>
            <tr className="table-row">
              <td>Reference</td>
              <td>{auditDetail?.reference_number}</td>
            </tr>
            <tr className="table-row">
              <td>Sector</td>
              <td>{auditDetail?.sector?.name}</td>
            </tr>
            <tr className="table-row">
              <td>Organization</td>
              <td>{auditDetail?.organization?.name}</td>
            </tr>
            <tr className="table-row">
              <td>Language</td>
              <td>{auditDetail?.language}</td>
            </tr>
            <tr className="table-row">
              <td>Category</td>
              <td>{auditDetail?.report?.name}</td>
            </tr>
            <tr className="table-row">
              <td>Status</td>
              <td>{auditDetail?.status}</td>
            </tr>
            <tr className="table-row">
              <td>Platform</td>
              <td>{auditDetail?.platform}</td>
            </tr>
            <tr className="table-row">
              <td>User</td>
              <td>{auditDetail?.report_from}</td>
            </tr>
            <tr className="table-row">
              <td>Report</td>
              <td>{auditDetail?.input_text}</td>
            </tr>
            <tr className="table-row">
              <td>Responses</td>
              <td>
                <div className="table-responsive react-table">
                  <Table
                    size="sm"
                    bordered
                    className="table-wrap table-hover mb-0"
                    responsive
                    striped
                  >
                    <tbody>
                      {auditDetail?.incoming_responses?.map((record, index) => (
                        <tr key={index}>
                          <td> {record.question}</td>
                          <td> {record.response}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
    </CustomModal>
  )
}
