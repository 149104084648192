import PropTypes from "prop-types";
import React from "react";
import { connect, useSelector } from "react-redux";
import withRouter from "components/Common/withRouter";
import { withTranslation } from "react-i18next";
import SidebarContent from "./SidebarContent";
import { Link } from "react-router-dom";
import logo from "../../assets/images/uwajibikaji.avif";
const Sidebar = props => {

  const { leftLogo } = useSelector((state) => ({
    leftLogo: state.Layout.leftLogo,
  }));

  return (
    <React.Fragment>
      <div className="vertical-menu">
        <div className="navbar-brand-box">
          <Link to="/dashboard" className="logo logo-dark">
            <span className="logo-sm">
              <img
                src={leftLogo || logo} alt="Uwajibikaji" height="12"
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '60px',
                  borderRadius: '5px'
                }}
                onError={(e) => {
                  e.target.src = logo
                }}
              />
            </span>
            <span className="logo-lg">
              <img
                src={leftLogo || logo} alt="Uwajibikaji" height="62"
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '60px',
                  borderRadius: '5px'
                }}
                onError={(e) => {
                  e.target.src = logo
                }}
              />
            </span>
          </Link>

          <Link to="/dashboard" className="logo logo-light">
            <span className="logo-sm">
              <img
                src={leftLogo || logo} alt="Uwajibikaji" height="12"
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '60px',
                  borderRadius: '5px'
                }}
                onError={(e) => {
                  e.target.src = logo
                }}

              />
            </span>
            <span className="logo-lg">
              <img
                src={leftLogo || logo} alt="Uwajibikaji" height="62"
                style={{
                  objectFit: 'contain',
                  width: '100%',
                  height: '60px',
                  borderRadius: '5px'
                }}

                onError={(e) => {
                  e.target.src = logo
                }}
              />
            </span>
          </Link>
        </div>
        <div data-simplebar className="h-100">
          {props.type !== "condensed" ? <SidebarContent /> : <SidebarContent />}
        </div>
        <div className="sidebar-background"></div>
      </div>
    </React.Fragment>
  );
};

Sidebar.propTypes = {
  type: PropTypes.string,
};

const mapStatetoProps = state => {
  return {
    layout: state.Layout,
  };
};
export default connect(
  mapStatetoProps,
  {}
)(withRouter(withTranslation()(Sidebar)));
